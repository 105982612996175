<template>
    <v-card :loading="loading">
        <v-card-title> {{ $t('kiosk.lang_frontPageTemplates') }}<v-spacer /><v-btn color="primary" depressed
                @click="showDialog=true">{{ $t("generic.lang_add") }}</v-btn></v-card-title>
        <v-divider class="ma-0" />
        <v-card-text class="pa-0">
            <v-data-table :items="templates" :headers="datatableHeaders">
                <template v-slot:item.crud="{ item }" class="">
                    <div class="d-flex align-center justify-end">
                        <v-btn color="success" x-small fab depressed @click="editTemplate(item.id)">
                            <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn fab x-small color="error" depressed @click="deleteTemplate(item.id)">
                            <v-icon x-small>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>
        </v-card-text>
        <CreateUpdateFrontPageTemplate v-if="showDialog" :templateId="id" @closeDialog="closeDialog" />
    </v-card>
</template>

<script lang="js">
import { ENDPOINTS } from '@/config';
import { Events } from "@/plugins/events";
import CreateUpdateFrontPageTemplate from './CreateUpdateFrontPageTemplate.vue';

export default {
    name: 'KioskFrontPageTemplates',
    components: {CreateUpdateFrontPageTemplate},
    data(){
        return {
            loading: false,
            showDialog: false,
            id: null,
            templates:[],
        }
    },
    computed:{
        datatableHeaders(){
            return [
                {
                    text: '',
                    value: 'id',
                    hidden: true,
                },
                {
                    text: this.$t("erp.lang_TemplateName"),
                    value: 'name',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'crud',
                    sortable: false,
                    align: 'right'
                }
            ]
        }
    },
    methods: {
        getTemplates(){
            this.loading = true;
            this.axios.post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.TEMPLATE.GETALL)
                .then(res => {
                    if (res.data.success) {
                        this.templates = Object.values(res.data.data);
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(err => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                })
        },
        editTemplate(id){
            this.id = id;
            this.showDialog = true;
        },
        deleteTemplate(id) {
            this.$swal({
                title: this.$t('settings.lang_deleteTemplate'),
                text: this.$t('generic.lang_deleteTemplateMsg'),
                icon: "warning",
                confirmButtonText: this.$t('generic.lang_delete'),
                cancelButtonText: this.$t('generic.lang_cancel'),
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    this.loading = true;

                    this.axios
                        .post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.TEMPLATE.DELETE, {
                            id: id
                        })
                        .then((res) => {
                            if (res.data.success) {
                                this.getTemplates();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred') + ': ' + res.data.msg,
                                    color: "error"
                                });
                            }
                        })
                        .catch((err) => {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_errorOccurred') + ': ' + err.message,
                                color: "error"
                            });
                        }).finally(() => {
                            this.loading = false;
                        })
                },
                allowOutsideClick: () => false,
            });
        },
        closeDialog(refresh=false){
            this.showDialog = false;
            this.id = null;
            if(refresh){
                this.getTemplates();
            }
        }
    },
    mounted(){
        this.getTemplates();
    }
}

</script>