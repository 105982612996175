<template>
    <v-card :loading="loading">
        <v-card-title> {{ $t('kiosk.lang_frontPageContent') }}</v-card-title>
        <v-divider class="ma-0" />
        <v-card-text class="pa-0">
            <v-container fluid>
                <v-row dense>
                    <v-col cols="12">
                        <v-alert text color="info" class="text-center mb-0">{{$t("generic.lang_preferableBannerImageDims")}}</v-alert>
                    </v-col>
                    <v-col v-if="productImageUpload" cols="12" class="justify-center">
                        <v-img height="100" contain :src="productImageUpload"></v-img>
                    </v-col>
                    <v-col cols="6">
                        <v-btn color="primary" @click="triggerFileInput"><v-icon
                            style="margin-right: 10px;">backup</v-icon>{{
                                $t('generic.lang_uploadBanner') }}</v-btn>
                        <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload"
                        accept="image/*">
                    </v-col>
                    <v-col cols="6">
                        <v-btn @click="removeBackgroundImage" v-if="productImageUpload" color="error"><v-icon
                                style="margin-right: 10px;">delete</v-icon>{{
                            $t('generic.lang_removeBanner') }}</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <TranslationInput v-model="firstSectionText" outlined dense @focus="showTouchKeyboard"
                            :settings='[
                                { locale: "de", label: $t("generic.lang_featuredGroupsSectionText", "de"), country: "DE" },
                                { locale: "en", label: $t("generic.lang_featuredGroupsSectionText", "en"), country: "GB" },
                                { locale: "fr", label: $t("generic.lang_featuredGroupsSectionText", "fr"), country: "FR" },
                                { locale: "ar", label: $t("generic.lang_featuredGroupsSectionText", "ar"), country: "SA" },
                            ]' :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[rules.required]" />
                    </v-col>
                    <v-col cols="12">
                        <TranslationInput v-model="secondSectionText" outlined dense @focus="showTouchKeyboard"
                            :settings='[
                                { locale: "de", label: $t("generic.lang_bannerImageSectionText", "de"), country: "DE" },
                                { locale: "en", label: $t("generic.lang_bannerImageSectionText", "en"), country: "GB" },
                                { locale: "fr", label: $t("generic.lang_bannerImageSectionText", "fr"), country: "FR" },
                                { locale: "ar", label: $t("generic.lang_bannerImageSectionText", "ar"), country: "SA" },
                            ]' :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[rules.required]" />
                    </v-col>
                    <v-col cols="12">
                        <TranslationInput v-model="thirdSectionText" outlined dense @focus="showTouchKeyboard"
                            :settings='[
                                { locale: "de", label: $t("generic.lang_featuredItemsSectionText", "de"), country: "DE" },
                                { locale: "en", label: $t("generic.lang_featuredItemsSectionText", "en"), country: "GB" },
                                { locale: "fr", label: $t("generic.lang_featuredItemsSectionText", "fr"), country: "FR" },
                                { locale: "ar", label: $t("generic.lang_featuredItemsSectionText", "ar"), country: "SA" },
                            ]' :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[rules.required]" />
                    </v-col>
                    <v-col cols="12">
                        <v-btn depressed color="success" @click="updateFrontPageContent" :loading="loading">
                            {{ $t('generic.lang_save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
                :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidthExpanded" />
        </div>
    </v-card>
</template>

<script>
import { ENDPOINTS } from '@/config';
import { Events } from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns"
import validation from "@/mixins/validation/validation";
import TranslationInput from "@/components/common/TranslationInput";
import { mapGetters } from 'vuex';

export default {
    name: "KioskFrontPageContent",
    mixins: [mixin, validation],
    components: {
        TranslationInput
    },
    data() {
        return {
            loading: false,
            deleteImage: false,
            updateImage: false,
            productImageUpload: null,
            firstSectionText: null,
            secondSectionText: null,
            thirdSectionText: null,
        }
    },
    computed:{
        ...mapGetters({
            imageUrl: "api/auth/imageUrl",
        }),
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.updateImage = true;
                    this.productImageUpload = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        removeBackgroundImage() {
            this.deleteImage = true;
            this.productImageUpload = null;
        },
        updateFrontPageContent() {
            this.loading = true;
            this.axios.post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.UPDATE, {
                deleteImage: this.deleteImage,
                updateImage: this.updateImage,
                productImageUpload: this.productImageUpload,
                firstSectionText: this.firstSectionText,
                secondSectionText: this.secondSectionText,
                thirdSectionText: this.thirdSectionText,
            }).then(res => {
                if (res.status === 200) {
                    this.updateImage = false;
                    this.deleteImage = false;
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_actionSuccessful'),
                        color: "success"
                    });
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(err => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
                    color: "error"
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        getFrontPageContent() {
            this.axios.post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.GET)
                .then(res => {
                    if (res.status === 200) {

                        this.productImageUpload = res.data.frontPageBanner ? this.imageUrl(res.data.frontPageBanner): null;
                        this.firstSectionText = res.data.firstSectionText;
                        this.secondSectionText = res.data.secondSectionText;
                        this.thirdSectionText = res.data.thirdSectionText;
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(err => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
                        color: "error"
                    });
                })
        }
    },
    mounted(){
        this.getFrontPageContent();
    }
}
</script>