<template>
  <v-card width="100%" height="250px" :style="customStyle" elevation="1" class="pa-2 rounded-lg text-center justify-center align-center">
    <v-card-text class="body-1 font-weight-bold d-flex justify-end pa-1">
      {{ itemPrice | currency }}
    </v-card-text>

    <v-img :src="itemImage" alt="" height="150" contain>
      <template v-slot:placeholder>
        <v-img contain height="100%" src="@/assets/images/defaultGroupImage.png" />
      </template>
    </v-img>

    <v-card-title class="font-weight-bold justify-center align-center py-1">
      <h5 class="text-overflow-2 mb-0 text-center">
        {{ itemName }}
      </h5>
    </v-card-title>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "ItemComponent",
  props: {
    item: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  data(){
    return {
      itemData: null
    }
  },
  watch:{
    "item.itemId": async function(id){
      await this.getItemData(id)
    }
  },
  computed: {
    ...mapGetters({
      imageUrl: "api/auth/imageUrl",
    }),
    itemImage(){
      if(this.itemData && this.itemData.productImage)
        return this.imageUrl(this.itemData.productImage);

      return null
    },
    itemPrice() {
      if(!this.itemData)
        return (Math.random()*100).toFixed(2)

      return this.itemData.sellPrice;
    },
    itemName(){
      if(!this.itemData)
        return "Item Name";

      return this.itemData.name.length > 50 ? this.itemData.name.substring(0, 50) + "..." : this.itemData.name;
    },
    customStyle() {
      return {
        backgroundColor: this.item.style.backgroundColor + ' !important',
        borderStyle: 'solid',
        borderColor: this.item.style.borderColor + ' !important',
        borderWidth: this.item.style.borderWidth + 'px !important',
        borderRadius: this.item.style.borderRadius + 'px !important',
      }
    }
  },
  methods: {
    async getItemData(id){
      if(id){
        const result = await this.$store.dispatch('items/getItemByID', id)
        if(result){
          this.itemData  = result;
          return;
        }
      }
      this.itemData = null;
    }
  },
  mounted(){
    this.getItemData(this.item?.itemId);
  }
};
</script>
