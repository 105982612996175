var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('kiosk.lang_frontPageContent')))]),_c('v-divider',{staticClass:"ma-0"}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"text-center mb-0",attrs:{"text":"","color":"info"}},[_vm._v(_vm._s(_vm.$t("generic.lang_preferableBannerImageDims")))])],1),(_vm.productImageUpload)?_c('v-col',{staticClass:"justify-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"height":"100","contain":"","src":_vm.productImageUpload}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.triggerFileInput}},[_c('v-icon',{staticStyle:{"margin-right":"10px"}},[_vm._v("backup")]),_vm._v(_vm._s(_vm.$t('generic.lang_uploadBanner')))],1),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.handleFileUpload}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.productImageUpload)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.removeBackgroundImage}},[_c('v-icon',{staticStyle:{"margin-right":"10px"}},[_vm._v("delete")]),_vm._v(_vm._s(_vm.$t('generic.lang_removeBanner')))],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('TranslationInput',{attrs:{"outlined":"","dense":"","settings":[
                            { locale: "de", label: _vm.$t("generic.lang_featuredGroupsSectionText", "de"), country: "DE" },
                            { locale: "en", label: _vm.$t("generic.lang_featuredGroupsSectionText", "en"), country: "GB" },
                            { locale: "fr", label: _vm.$t("generic.lang_featuredGroupsSectionText", "fr"), country: "FR" },
                            { locale: "ar", label: _vm.$t("generic.lang_featuredGroupsSectionText", "ar"), country: "SA" },
                        ],"data-layout":_vm.KEYBOARD.KEYSETS.NORMAL,"rules":[_vm.rules.required]},on:{"focus":_vm.showTouchKeyboard},model:{value:(_vm.firstSectionText),callback:function ($$v) {_vm.firstSectionText=$$v},expression:"firstSectionText"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('TranslationInput',{attrs:{"outlined":"","dense":"","settings":[
                            { locale: "de", label: _vm.$t("generic.lang_bannerImageSectionText", "de"), country: "DE" },
                            { locale: "en", label: _vm.$t("generic.lang_bannerImageSectionText", "en"), country: "GB" },
                            { locale: "fr", label: _vm.$t("generic.lang_bannerImageSectionText", "fr"), country: "FR" },
                            { locale: "ar", label: _vm.$t("generic.lang_bannerImageSectionText", "ar"), country: "SA" },
                        ],"data-layout":_vm.KEYBOARD.KEYSETS.NORMAL,"rules":[_vm.rules.required]},on:{"focus":_vm.showTouchKeyboard},model:{value:(_vm.secondSectionText),callback:function ($$v) {_vm.secondSectionText=$$v},expression:"secondSectionText"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('TranslationInput',{attrs:{"outlined":"","dense":"","settings":[
                            { locale: "de", label: _vm.$t("generic.lang_featuredItemsSectionText", "de"), country: "DE" },
                            { locale: "en", label: _vm.$t("generic.lang_featuredItemsSectionText", "en"), country: "GB" },
                            { locale: "fr", label: _vm.$t("generic.lang_featuredItemsSectionText", "fr"), country: "FR" },
                            { locale: "ar", label: _vm.$t("generic.lang_featuredItemsSectionText", "ar"), country: "SA" },
                        ],"data-layout":_vm.KEYBOARD.KEYSETS.NORMAL,"rules":[_vm.rules.required]},on:{"focus":_vm.showTouchKeyboard},model:{value:(_vm.thirdSectionText),callback:function ($$v) {_vm.thirdSectionText=$$v},expression:"thirdSectionText"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"depressed":"","color":"success","loading":_vm.loading},on:{"click":_vm.updateFrontPageContent}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_save'))+" ")])],1)],1)],1)],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.dismissOnScreenKeyboard),expression:"dismissOnScreenKeyboard"}],attrs:{"id":"onScreenKeyboardDiv"}},[(_vm.touchKeyboard.visible)?_c('vue-touch-keyboard',{staticClass:"internalWidthExpanded",attrs:{"id":"onScreenKeyboard","accept":_vm.hideTouchKeyboard,"cancel":_vm.hideTouchKeyboard,"defaultKeySet":_vm.touchKeyboard.keySet,"input":_vm.touchKeyboard.input,"layout":_vm.touchKeyboard.layout,"options":_vm.touchKeyboard.options}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }