<template>
  <div>
    <page-title
      :heading="$t('kiosk.lang_kiosk_design_config')"
      :subheading="$t('kiosk.lang_kiosk_design_config')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <kiosk-design-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import KioskDesignComponent from "@/components/kiosk/design/KioskDesignComponent";

export default {
  name: "KioskDesignView",
  components: {
    KioskDesignComponent,
    PageTitle,
  },

  data: () => ({
    icon: "pe-7s-config icon-gradient bg-tempting-azure",
  }),
};
</script>
