<template>
  <v-card width="100%" height="200px" :style="customStyle" elavation="1"
    class="rounded-lg d-flex justify-space-between align-center overflow-hidden">
    <div class="product-img-cont">
      <v-img :src="itemImage" alt="" contain height="100%">
        <template v-slot:placeholder>
          <v-img contain height="100%" src="@/assets/images/defaultGroupImage.png"/>
        </template>
      </v-img>
    </div>
    <div class="product-info-cont">
      <v-card-title class="font-weight-bold d-flex justify-space-between pt-1 pr-2">
        <h4 class="mb-0">{{ itemName }}</h4>
        <span>{{ itemPrice | currency}}</span>
      </v-card-title>
      <v-card-text class="text-overflow-8" v-html="itemDescription">
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import { mapGetters } from 'vuex';

export default {
  name: "ExtendedItemComponent",
  components:{
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  data(){
    return {
      itemData: null
    }
  },
  watch:{
    "item.itemId": async function(id){
      await this.getItemData(id);
    }
  },
  computed: {
    ...mapGetters({
      imageUrl: "api/auth/imageUrl",
    }),
    itemImage(){
      if(this.itemData && this.itemData.productImage)
        return this.imageUrl(this.itemData.productImage);

      return null
    },
    itemPrice() {
      if(!this.itemData)
        return (Math.random()*100).toFixed(2)

      return this.itemData.sellPrice;
    },
    itemName(){
      if(!this.itemData)
        return "Item Name";

      return this.itemData.name.length > 50 ? this.itemData.name.substring(0, 50) + "..." : this.itemData.name;
    },
    itemDescription(){
      if(!this.itemData)
        return "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to mak";      

      return this.itemData.description
    },
    customStyle() {
      return {
        backgroundColor: this.item.style.backgroundColor + ' !important',
        borderStyle: 'solid',
        borderColor: this.item.style.borderColor + ' !important',
        borderWidth: this.item.style.borderWidth + 'px !important',
        borderRadius: this.item.style.borderRadius + 'px !important',
      }
    }
  },
  methods: {
    async getItemData(id){
      if(id){
        const result = await this.$store.dispatch('items/getItemByID', id)
        if(result){
          this.itemData  = result;
          return;
        }
      }
      this.itemData = null;
    }
  },
  mounted(){
    this.getItemData(this.item?.itemId);
  }
};
</script>
<style scoped>
.product-img-cont{
  height: 100%;
  width: 40%;
}
.product-info-cont{
  height: 100%;
  width: 60%;
  position: relative;
}

.product-actions{
  position: absolute; 
  bottom: 0;
  right: 0;
}
</style>