<template>
    <v-dialog :value="true" class="ma-0 overflow-y-hidden" fullscreen scrollable permanent>
        <v-card elevation="0" max-height="100%" height="100%" class="oveflow-hidden">
            <v-card-title class="text-right pl-0 py-0">
                <v-row no-gutters class="ma-0">
                    <v-col cols="4">
                        <v-tabs v-model="edit" color="primary" grow dense>
                            <v-tab>
                                <font-awesome-icon :icon="['fad', 'plus']" size="2x" />
                            </v-tab>
                            <v-tab :disabled="!selectedComponent">
                                <font-awesome-icon :icon="['fad', 'cogs']" size="2x" />
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col cols="8" align="right" align-self="center">
                        <v-btn :color="hideHandles?'':'primary'" depressed small fab @click="hideHandles=!hideHandles">
                            <v-icon>mdi-select</v-icon>
                        </v-btn>
                        <v-btn @click="$emit('closeDialog')" class="ma-0 mx-1" small color="error" text>{{
                            $t('generic.lang_cancel') }}</v-btn>
                        <v-btn class="ma-0 mx-1" small color="success" :disabled="!templateName" @click="saveTemplate">{{ templateId ? $t('generic.lang_update') : $t('generic.lang_save') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider class="ma-0" />
            <v-card-text class="pa-0 main-cont" @click="hideHandles=false">
                <v-container fluid class="h-100 pa-0">
                    <v-row no-gutters class="h-100">
                        <v-col cols="4" class="left-panel overflow-y-auto">
                            <v-text-field class="mt-2 mx-4" v-model="templateName" :rules="[rules.required]" :label="$t('erp.lang_TemplateName')" dense outlined/>
                            <v-divider class="ma-0"/>
                            <v-tabs-items v-model="edit">
                                <v-tab-item class="pa-1">
                                    <v-sheet key="components" class="items-cont px-2 pb-6 overflow-y-auto w-100" rounded
                                        height="100%" color="lightgrey">
                                        <draggable :list="blocks" :group="{ name: 'blocks', pull: 'clone', put: false }"
                                            class="row row--dense ma-0" :clone="cloneComponent">
                                            <v-col cols="6" v-for="(element, index) in blocks"
                                                :key="element.type + '-' + index">
                                                <v-sheet @click="addBlock(element)" v-ripple
                                                    class="no-text-selection item-component pa-1 d-flex align-center justify-center"
                                                    height="100" rounded elevation="1">
                                                    <div class="text-center w-100">
                                                        <font-awesome-icon color="grey" :icon="['fad', element.icon]"
                                                            size="2x" />
                                                        <p class="text-primary text-center mb-0">{{ element.name }} </p>
                                                    </div>
                                                </v-sheet>
                                            </v-col>
                                        </draggable>
                                    </v-sheet>
                                </v-tab-item>
                                <v-tab-item class="pa-1">
                                    <v-card tile class="ma-0 pa-0" elevation="0" height="100%">
                                        <v-card-text class="pa-0" v-if="selectedComponent">
                                            <template v-if="selectedComponent.type === 'item'">
                                                <div>
                                                    <h6>{{ $t('erp.lang_item') }} </h6>
                                                    <v-autocomplete :items="itemGroupHolder" item-text="name"
                                                        item-value="id" dense v-model="waregroupFilter" clearable
                                                        small-chips deletable-chips @change="getItems(waregroupFilter)"
                                                        :label="$t('erp.lang_filterByWaregroup')" outlined />
                                                    <v-autocomplete v-model="selectedComponent.itemId" :items="items"
                                                        small-chips deletable-chips item-text="name" item-value="id"
                                                        dense :label="$t('erp.lang_items')" outlined />
                                                </div>
                                            </template>
                                            <template v-if="selectedComponent.type === 'group'">
                                                <div>
                                                    <h6>{{ $t('generic.lang_waregroup') }}: </h6>
                                                    <v-autocomplete v-model="selectedComponent.itemGroupId" small-chips
                                                        deletable-chips :items="itemGroupHolder" item-text="name"
                                                        item-value="id" dense outlined />
                                                </div>
                                            </template>
                                            <div v-if="selectedComponent.type === 'vgroup'" class="py-1">
                                                <div>
                                                    <h6>{{ $t('generic.lang_waregroup') }}: </h6>
                                                    <v-autocomplete v-model="selectedComponent.assignments.groups"
                                                        :items="itemGroupHolder" item-text="name" item-value="id"
                                                        multiple small-chips deletable-chips dense outlined />
                                                </div>
                                                <div>
                                                    <h6>{{ $t('erp.lang_items') }} </h6>
                                                    <v-autocomplete :items="itemGroupHolder" item-text="name"
                                                        item-value="id" :label="$t('erp.lang_filterByWaregroup')" dense
                                                        small-chips deletable-chips v-model="waregroupFilter" clearable
                                                        @change="getItems(waregroupFilter)" outlined />
                                                    <v-autocomplete v-model="selectedComponent.assignments.items" @blur="waregroupFilter=null"
                                                        :items="items" item-text="name" item-value="id" dense multiple
                                                        small-chips deletable-chips :label="$t('erp.lang_items')"
                                                        outlined />
                                                </div>
                                                <TranslationInput v-model="selectedComponent.title" outlined dense
                                                    :settings='[
                                            { locale: "fr", label: $t("generic.lang_title", "fr"), country: "FR" },
                                            { locale: "de", label: $t("generic.lang_title", "de"), country: "DE" },
                                            { locale: "en", label: $t("generic.lang_title", "en"), country: "GB" },
                                            { locale: "ar", label: $t("generic.lang_title", "ar"), country: "SA" },
                                        ]' :rules="[rules.required]" />
                                            </div>
                                            <div v-if="selectedComponent.type === 'text'">
                                                <h3>{{ $t("generic.lang_content") }}</h3>
                                                <TranslationInput v-model="selectedComponent.content"
                                                    mode="quill-editor" outlined dense :settings='[
                                                { locale: "fr", label: $t("generic.lang_content", "fr"), country: "FR" },
                                                { locale: "de", label: $t("generic.lang_content", "de"), country: "DE" },
                                                { locale: "en", label: $t("generic.lang_content", "en"), country: "GB" },
                                                { locale: "ar", label: $t("generic.lang_content", "ar"), country: "SA" },
                                            ]' />
                                            </div>
                                            <div v-if="selectedComponent.type === 'banner'">
                                                <v-tabs v-model="selectedComponent.target" dense>
                                                    <v-tab>
                                                        {{ $t('erp.lang_item') }}
                                                    </v-tab>
                                                    <v-tab>
                                                        {{ $t('generic.lang_waregroup') }}
                                                    </v-tab>
                                                </v-tabs>
                                                <v-tabs-items v-model="selectedComponent.target">
                                                    <v-tab-item class="pa-1">
                                                        <div>
                                                            <h6>{{ $t('erp.lang_item') }} </h6>
                                                            <v-autocomplete :items="itemGroupHolder" item-text="name"
                                                                small-chips deletable-chips item-value="id" dense
                                                                v-model="waregroupFilter" clearable
                                                                :label="$t('erp.lang_filterByWaregroup')"
                                                                @change="getItems(waregroupFilter)" outlined />
                                                            <v-autocomplete v-model="selectedComponent.itemId"
                                                                small-chips deletable-chips :items="items"
                                                                item-text="name" item-value="id" dense
                                                                :label="$t('erp.lang_items')" outlined />
                                                        </div>
                                                    </v-tab-item>
                                                    <v-tab-item class="pa-1">
                                                        <div>
                                                            <h6>{{ $t('generic.lang_waregroup') }}: </h6>
                                                            <v-autocomplete v-model="selectedComponent.itemGroupId"
                                                                small-chips deletable-chips :items="itemGroupHolder"
                                                                item-text="name" item-value="id" dense outlined />
                                                        </div>
                                                    </v-tab-item>
                                                </v-tabs-items>
                                            </div>
                                            <div v-if="haveAssets" class="py-1">
                                                <div>
                                                    <h3>Assets</h3>
                                                    <draggable tag="div" :list="selectedComponent.assets"
                                                        :group="{ name: 'aseets' }" draggable='.draggable-asset-item'
                                                        v-bind="dragOptions">
                                                        <v-sheet dense class="asset-item"
                                                            :class="asset.isExpanded ? '' : 'draggable-asset-item'"
                                                            v-for="(asset, index) in selectedComponent.assets"
                                                            :key="index">
                                                            <div class="asset-header d-flex align-center"
                                                                @click="toggleExpand(asset)">
                                                                <div class="flex-grow-1 cursor-pointer">
                                                                    {{ asset.title }}
                                                                </div>
                                                                <v-btn icon small @click.stop="duplicateAsset(index)">
                                                                    <v-icon>mdi-content-copy</v-icon>
                                                                </v-btn>
                                                                <v-btn icon small @click.stop="removeAsset(index)">
                                                                    <v-icon>mdi-close</v-icon>
                                                                </v-btn>
                                                            </div>
                                                            <v-expand-transition>
                                                                <div v-show="asset.isExpanded"
                                                                    class="asset-content pa-2">
                                                                    <v-text-field v-model="asset.title" label="Title"
                                                                        dense outlined></v-text-field>
                                                                    <v-text-field v-model.number="asset.timer"
                                                                        type="number" :min="2"
                                                                        label="Slider Time interval (in seconds default 2)"
                                                                        dense outlined></v-text-field>

                                                                    <div>
                                                                        <v-tabs v-model="asset.type" grow dense>
                                                                            <v-tab key="image">
                                                                                <font-awesome-icon class="pl-2"
                                                                                    :icon="['fad', 'image']"
                                                                                    size="2x" />
                                                                            </v-tab>
                                                                            <v-tab key="video">
                                                                                <font-awesome-icon class="pl-2"
                                                                                    :icon="['fad', 'video']"
                                                                                    size="2x" />
                                                                            </v-tab>
                                                                        </v-tabs>
                                                                        <v-tabs-items v-model="asset.type">
                                                                            <v-tab-item key="image" class="pa-1">
                                                                                <v-row no-gutters>
                                                                                    <v-col
                                                                                        v-if="selectedComponent.type === 'banner'"
                                                                                        cols="12" class="py-1">
                                                                                        <v-alert text color="info"
                                                                                            class="text-center mb-0">{{
                                                                                            $t("generic.lang_preferableBannerImageDims")
                                                                                            }}</v-alert>
                                                                                    </v-col>
                                                                                    <v-col cols="12"
                                                                                        class="justify-center py-1">
                                                                                        <v-img height="100" contain
                                                                                            :src="asset.src">
                                                                                            <template
                                                                                                v-slot:placeholder>
                                                                                                <div
                                                                                                    class="text-muted text-center w-100 h-100 d-flex align-center justify-center border-dashed">
                                                                                                    <font-awesome-icon
                                                                                                        :icon="['fad', 'image']"
                                                                                                        size="2x" />
                                                                                                </div>
                                                                                            </template>
                                                                                        </v-img>
                                                                                    </v-col>
                                                                                    <v-col cols="12" class="py-1">
                                                                                        <v-btn class="ma-0 my-1" small
                                                                                            color="primary"
                                                                                            @click="triggerFileInput(index)"><v-icon
                                                                                                style="margin-right: 10px;">backup</v-icon>{{
                                                                                            $t('generic.lang_uploadImage')
                                                                                            }}</v-btn>
                                                                                        <input type="file"
                                                                                            :id="'assetInput' + index"
                                                                                            style="display: none"
                                                                                            @change="handleFileUpload($event, asset)"
                                                                                            accept="image/png">
                                                                                    </v-col>
                                                                                    <v-col cols="12" class="py-1">
                                                                                        <v-btn class="ma-0 my-1" small
                                                                                            @click="removeBackgroundImage(asset)"
                                                                                            color="error"><v-icon
                                                                                                style="margin-right: 10px;">delete</v-icon>{{
                                                                                            $t('generic.lang_delete')
                                                                                            }}</v-btn>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-tab-item>
                                                                            <v-tab-item key="video" class="pa-1">
                                                                                <v-text-field v-model="asset.url"
                                                                                    label="video url" dense outlined
                                                                                    clearable></v-text-field>
                                                                                <div v-if="asset.url">
                                                                                    <v-alert v-if="asset.error"
                                                                                        type="error" dense outlined>
                                                                                        Error loading video. Please
                                                                                        check
                                                                                        the
                                                                                        URL
                                                                                        and
                                                                                        try again.
                                                                                    </v-alert>
                                                                                    <video preload="auto"
                                                                                        :key="'video-' + asset.url"
                                                                                        width="100%" height="100%"
                                                                                        autoplay loop muted playsinline
                                                                                        v-on:error="onVideoError(asset)"
                                                                                        @canplay="onVideoCanPlay(asset)">
                                                                                        <source :src="asset.url">
                                                                                        some error
                                                                                    </video>
                                                                                </div>
                                                                            </v-tab-item>
                                                                        </v-tabs-items>
                                                                    </div>

                                                                    <!-- Add more fields here as needed, e.g., for asset URL, description, etc. -->
                                                                </div>
                                                            </v-expand-transition>
                                                        </v-sheet>
                                                    </draggable>
                                                    <div class="text-center">
                                                        <v-btn color="primary" class="mt-4" @click="addAsset">
                                                            + Add Asset
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="haveStyle">
                                                <h3>Style</h3>
                                                <v-sheet dense class="asset-item">
                                                    <v-row dense class="ma-0">
                                                        <v-col cols="6">
                                                            <v-text-field v-model.number="selectedComponent.style.borderWidth" type="number" hide-details
                                                                :min="0"
                                                                label="Border width"
                                                                dense outlined></v-text-field>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field v-model.number="selectedComponent.style.borderRadius" type="number" hide-details
                                                                :min="0"
                                                                label="Border radius"
                                                                dense outlined></v-text-field>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <ColorPicker mode="hexa" dense outlined :label="'Border color'" :hide-details="true"
                                                                :color="selectedComponent.style.borderColor" v-model="selectedComponent.style.borderColor"  />
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <ColorPicker mode="hexa" dense outlined :label="'Background color'" :hide-details="true"
                                                                :color="selectedComponent.style.backgroundColor" v-model="selectedComponent.style.backgroundColor"  />
                                                        </v-col>
                                                    </v-row>
                                                </v-sheet>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                        <v-col class="no-text-selection overflow-y-auto h-100"
                            :class="{ 'border-dashed-2': components.length < 1 }" cols="8">
                            <draggable class="dragArea row no-gutters ma-0" :list="components" group="blocks" v-bind="dragOptions">
                                <div v-if="components.length < 1" class="text-muted text-center empty-content"
                                    key="emty-content">
                                    <v-icon size="100">mdi-inbox-arrow-down-outline</v-icon>
                                    <h3>Drag and drop a component</h3>
                                </div>
                                <v-col v-else :cols="cols(component)" v-for="(component, index) in components"
                                    :key="index + component.id">
                                    <CustomComponent :component="component" @remove="removeAt(index)" :hideHandles="hideHandles" :isSelected="selectedComponent?.id === component.id"
                                        @editTextContent="component.edit = true"
                                        @updateTextContent="updateTextContent($event, component)"
                                        @edit="showEdit(component)" />
                                </v-col>
                            </draggable>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <!-- Edit dialog -->

        </v-card>
    </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faImage, faPhotoVideo, faVideo, faBurgerSoda, faArrowsAltV, faText, faThLarge, faSitemap, faCogs, faPlus } from "@fortawesome/pro-duotone-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core";
import randomString from "randomstring";
import CustomComponent from "./CustomComponent.vue";
import TranslationInput from "@/components/common/TranslationInput.vue";
import validation from "../../../../mixins/validation/validation";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import ColorPicker from "../../../common/colorPicker.vue";
import { ENDPOINTS } from '@/config';
import { Events } from "@/plugins/events";

library.add(faPhotoVideo, faImage, faVideo, faBurgerSoda, faArrowsAltV, faText, faThLarge, faSitemap, faCogs, faPlus);

export default {
    name: "CreateUpdateFrontPageTemplate",
    components: { draggable, FontAwesomeIcon, CustomComponent, TranslationInput, ColorPicker},
    mixins: [validation],
    props:['templateId'],
    data() {
        return {
            components: [

            ],
            drag: false,
            hideHandles: false,
            loading: false,
            edit: 0,
            templateName: null,
            selectedComponent: null,
            waregroupFilter: null,
            items: [],
            itemGroupHolder: [],
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
            };
        },
        blocks() {
            return [
                {
                    name: 'Text',
                    type: 'text',
                    icon: 'text',
                    content: null,
                },
                {
                    name: 'Banner',
                    type: 'banner',
                    icon: 'photo-video',
                    style: {
                        borderWidth: 0,
                        borderRadius: 4,
                        borderColor: "#e0e0e0ff",
                        backgroundColor: "#ffffffff",

                    },
                    assets: [],
                    // where to send the user either an item page or group page
                    target: {
                        type: 0, // 0 item, 1 group
                        itemId: null,
                        itemGroupId: null
                    }
                },
                {
                    name: 'Spacer',
                    type: 'spacer',
                    icon: 'arrows-alt-v',
                    height: 50,
                },
                {
                    name: 'Virtual group',
                    type: 'vgroup',
                    icon: 'sitemap',
                    style: {
                        borderWidth: 0,
                        borderRadius: 4,
                        borderColor: "#e0e0e0ff",
                        backgroundColor: "#ffffffff",

                    },
                    title: null,
                    assets: [],
                    assignments: {
                        items: [],
                        groups: []
                    }
                },
                {
                    name: 'Item Group',
                    type: 'group',
                    icon: 'th-large',
                    style: {
                        borderWidth: 0,
                        borderRadius: 4,
                        borderColor: "#e0e0e0ff",
                        backgroundColor: "#ffffffff",

                    },
                    itemGroupId: null,
                },
                {
                    name: 'item',
                    type: 'item',
                    icon: 'burger-soda',
                    style: {
                        borderWidth: 0,
                        borderRadius: 4,
                        borderColor: "#e0e0e0ff",
                        backgroundColor: "#ffffffff",

                    },
                    extended: false,
                    itemId: null,
                },
                {
                    name: 'Extended item',
                    type: 'item',
                    icon: 'burger-soda',
                    style: {
                        borderWidth: 0,
                        borderRadius: 4,
                        borderColor: "#e0e0e0ff",
                        backgroundColor: "#ffffffff",

                    },
                    extended: true,
                    itemId: null,
                },
            ]
        },
        haveAssets() {
            if (!this.selectedComponent)
                return false;

            switch (this.selectedComponent.type) {
                case "banner":
                    return true;
                case "vgroup":
                    return true;
                default:
                    return false;
            }
        },
        haveStyle() {
            if (!this.selectedComponent)
                return false;

            switch (this.selectedComponent.type) {
                case "banner":
                case "vgroup":
                case "group":
                case "item":
                    return true;
                default:
                    return false;
            }

        }
    },
    methods: {
        addBlock(item){
            this.components.push(this.cloneComponent(item))
        },
        cloneComponent(item) {
            return {
                ...JSON.parse(JSON.stringify(item)),
                id: randomString.generate(10),
            }
        },
        removeAt(index) {
            if(this.edit && this.components[index].id === this.selectedComponent.id)
                this.hideEdit();

            this.components.splice(index, 1);
        },
        async showEdit(item) {
            this.edit = 1;
            this.selectedComponent = item;
            this.waregroupFilter = null;
            this.items = [];
            await this.getItemGroups();
            await this.getItems();
        },
        hideEdit() {
            this.edit = 0;
            this.selectedComponent = null;
        },
        cols(item) {
            switch (item.type) {
                case "item":
                    return item.extended ? 12 : 4;
                case "group":
                    return 6;
                case "vgroup":
                    return 6;
                default:
                    return 12;
            }
        },
        addAsset() {
            this.selectedComponent.assets.push({
                title: `Asset #${this.selectedComponent.assets.length + 1}`,
                url: null, // for video
                src: null, // for image
                type: 0,//0 image, 1 video 
                isExpanded: true,
                error: false,
                timer: 2,
            });
        },
        removeAsset(index) {
            this.selectedComponent.assets.splice(index, 1);
        },
        duplicateAsset(index) {
            const newAsset = JSON.parse(JSON.stringify(this.selectedComponent.assets[index]));
            newAsset.title += " dup"
            this.selectedComponent.assets.splice(index + 1, 0, newAsset);
        },
        toggleExpand(asset) {
            asset.isExpanded = !asset.isExpanded;
        },
        onVideoCanPlay(asset) {
            asset.error = false;
        },
        onVideoError(asset) {
            asset.error = true;
        },
        triggerFileInput(index) {
            document.getElementById('assetInput' + index)?.click();
        },
        handleFileUpload(event, asset) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    asset.src = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        removeBackgroundImage(asset) {
            asset.src = null;
        },
        async getItemGroups(){
            this.itemGroupHolder = [];

            //ITEMGROUPS
            this.itemGroupHolder = (await this.$store.dispatch("itemgroups/getItemgroups"))?.map((itemgroup) => {
                return {
                    id: itemgroup.id,
                    name: itemgroup.name
                }
            }).sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        },
        async getItems(id=null) {
            this.loading = true;

            this.items = id ? await this.$store.dispatch("items/getItemsByItemGroupIDSortByName", id) : await this.$store.dispatch("items/getActiveItemsSortByNameAsc");
            this.loading = false;
        },
        parseTemplate(){
            return this.components.map(el=>{
                
            })
        },
        createFrontPageTemplate(){
            this.loading = true;
            this.axios.post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.TEMPLATE.CREATE, {
                name: this.templateName,
                template: this.components,
            }).then(res => {
                if (res.data.success) {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_ActionSuccessful'),
                        color: "success"
                    });
                    this.$emit('closeDialog', true)
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(err => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
                    color: "error"
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        updateFrontPageTemplate(){
            this.loading = true;
            this.axios.post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.TEMPLATE.UPDATE, {
                id: this.templateId,
                name: this.templateName,
                template: this.components,
            }).then(res => {
                if (res.data.success) {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_ActionSuccessful'),
                        color: "success"
                    });
                    this.$emit('closeDialog', true)
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(err => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
                    color: "error"
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        saveTemplate(){
            if(this.templateId){
                this.updateFrontPageTemplate()
            }else {
                this.createFrontPageTemplate()
            }
        },
        getTemplateData(){
            this.loading = true;
            this.axios.post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.TEMPLATE.GET, {
                id: this.templateId,
            }).then(res => {
                if (res.data.success) {
                    this.templateName = res.data.template.name;
                    this.components = res.data.template.template;
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(err => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
                    color: "error"
                });
            }).finally(() => {
                this.loading = false;
            })
        },
    },
    mounted(){
        if(this.templateId){
            this.getTemplateData()
        }
    }
}
</script>
<style scoped>

.main-cont{
    height: calc(100vh - 54px);
}

.item-component {
    border: 1px solid var(--primary);
}

.left-panel {
    border-right: 2px solid lightgray;
    height: 100%;
    max-height: 100%;
}

.items-cont {
    z-index: 2;
    position: relative;
}

.border-dashed-2 {
    border: 2px dashed lightgray !important;
}

.border-dashed {
    border: 1px dashed lightgray !important;
}

.empty-content {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    height: 140px;
    width: 100%;
}

.edit-dialog-actions {
    position: static;
    bottom: 0;
}

.asset-item {
    border: 1px solid #e0e0e0;
}

.asset-header {
    padding: 8px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #949494; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

</style>