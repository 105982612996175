<template>
  <v-card class="pa-0 rounded-lg overflow-hidden" elevation="1" :style="customStyle" height="250">
    <v-container fluid class="pa-1 h-100" >
      <v-row no-gutters class="h-100">
        <v-col cols="6" align-self="center">
          <h2 v-text="groupName" class="pl-2 text-overflow-3"></h2>
        </v-col>
        <v-col cols="6" align="center" align-self="center" class="h-100">
          <v-carousel v-model="current" height="240" hide-delimiter-background hide-delimiters :show-arrows="false" cycle
            :inteval="interval">
            <template v-if="groupAssets">
              <v-carousel-item v-for="(asset, index) in groupAssets" :key="index" reverse-transition="fade-transition" class="align-center"
                transition="fade-transition">
                <v-img v-if="asset.type===0" :src="asset.src" height="100%" contain>
                  <template v-slot:placeholder>
                    <div class="text-muted text-center w-100 h-100 d-flex align-center justify-center">
                      <font-awesome-icon :icon="['fad', 'image']" size="2x" />
                    </div>
                  </template>
                </v-img>
                <video v-else preload="auto" class="video-player" :key="'vgroup-video-'+asset.src" width="100%" autoplay loop muted playsinline>
                  <source :src="asset.url">
                  some error
                </video>
              </v-carousel-item>
            </template>
            <template v-else>
              <v-carousel-item v-for="i in 3" :key="i" reverse-transition="fade-transition"
                transition="fade-transition">
                <div class="text-muted text-center w-100 h-100 d-flex align-center justify-center">
                  <font-awesome-icon v-if="i === 1" :icon="['fad', 'photo-video']" size="4x" />
                  <font-awesome-icon v-else-if="i === 2" :icon="['fad', 'image']" size="4x" />
                  <font-awesome-icon v-else :icon="['fad', 'video']" size="4x" />
                </div>
              </v-carousel-item>
            </template>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapGetters } from "vuex"

export default {
  name: "FeaturedGroup",
  components:{ FontAwesomeIcon },
  props: {
    group: {
      type: Object,
      default: () => null,
      required: false
    },
  },
  data(){
    return {
      current: 0,
    }
  },
  computed: {
    ...mapGetters({
      lang:"app/currentLanguage"
    }),
    groupAssets() {
      if (!this.group || this.group.assets.length < 1)
      return null;

      return this.group.assets;
    },
    groupName() {
      if (!this.group || !this.group.title)
        return "VGroup Name"+(Math.random()>0.5?"Very long one lets see how its being handled yeah you can't see this one LOL": "");
      
      
      return this.group.title[this.lang];
    },
    interval() {
      if (this.group.assets.length > 0)
        return 2000

      return this.group.assets[this.current]?.timer >= 2 ? this.group.assets[this.current]?.timer * 1000 : 2000
    },
    customStyle() {
      return {
        backgroundColor: this.group.style.backgroundColor + ' !important',
        borderStyle: 'solid',
        borderColor: this.group.style.borderColor + ' !important',
        borderWidth: this.group.style.borderWidth + 'px !important',
        borderRadius: this.group.style.borderRadius + 'px !important',
      }
    }
  }
}
</script>

<style scoped>
.video-player {
  position: absolute;
  inset: 0;
  margin: auto;
}
</style>