import Quill from "quill";
import QuillResize from "quill-resize-module";

Quill.register("modules/resize", QuillResize);

const FontAttributor = Quill.import("attributors/class/font");
let SizeStyle = Quill.import("attributors/style/size");
FontAttributor.whitelist = ["Poppins", "Roboto", "Nunito", "Dancingscript"];
SizeStyle.whitelist = [
	"8px",
	"10px",
	"12px",
	"14px",
	"16px",
	"18px",
	"20px",
	"24px",
	"28px",
	"32px",
	"36px",
	"40px",
	"48px",
	"56px",
	"64px",
	"72px",
	"80px",
	"96px",
	"128px",
];
Quill.register(FontAttributor, true);
Quill.register(SizeStyle, true);

FontAttributor.whitelist.forEach((font) => {
	const style = document.createElement("style");
	style.innerHTML = `.ql-font-${font} { font-family: ${font}; }`;
	document.head.appendChild(style);
});

const quillOptions = {
	modules: {
		toolbar: [
			[{ color: [] }],
			["image", "video", "link", "clean"],
			["bold", "italic", "underline", "strike"],
			[{ size: SizeStyle.whitelist }],
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			[{ align: [] }],
			[{ font: FontAttributor.whitelist }],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
		],
	},
};

export { quillOptions };
