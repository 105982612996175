<template>
  <v-row>
    <v-col cols="6">
      <v-row>

        <v-col cols="12">
          <v-text-field :min="0" :max="5" :disabled="this.borderForm.is_border_disabled" hide-details suffix="px"
                        v-model="borderForm.border_size" type="number" :label="$t('generic.lang_borderSize')"
                        dense
                        outlined/>
        </v-col>

        <v-col cols="12">
          <v-menu v-model="borderColorMenu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :disabled="borderForm.is_border_disabled" hide-details :value="borderForm.border_color"
                            readonly v-on="on" v-bind="attrs"
                            v-model="borderForm.border_color" :label="$t('generic.lang_borderColor')"
                            dense outlined>
                <template v-slot:append>
                  <v-icon :color="borderForm.border_color" large style="margin-top: -6px">stop</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-card>
              <v-card-text>
                <v-color-picker
                    class="ma-2"
                    mode="hexa"
                    v-model="borderColorWatcher"
                ></v-color-picker>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="applyColor" depressed class="mx-auto" block color="primary">
                  {{ $t('generic.lang_save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>


        <v-col cols="12">
          <v-text-field :min="0" :max="30" :disabled="this.borderForm.is_border_disabled" hide-details suffix="px"
                        v-model="borderForm.border_radius" type="number" :label="$t('generic.lang_borderRadius')"
                        dense
                        outlined/>
        </v-col>
      </v-row>
    </v-col>
    <!--start: demo card-->
    <v-col cols="6">
      <!--      <v-switch :value="true" label="Disable Border" v-model="borderForm.is_border_disabled"/>-->
      <v-card
          :outlined="!this.borderForm.is_border_disabled"
          v-bind:style="{
              'border': `${borderForm.border_size}px solid ${borderForm.border_color}`,
              'border-radius': `${borderForm.border_radius}px !important`
                }"
          class="align-center text-start rounded-lg px-0"
          elevation="0"
          width="90%"
      >
        <v-card-text class="pa-0 px-0 ma-0 mx-0">
          <!--***-->
          <v-list-item three-line class="mx-0">
            <v-list-item-content>
              <v-list-item-title
                  class="font-weight-bold mb-0 text-lg-h6 product_name--text "
              >
                Lorem ipsum dolor sit amet
              </v-list-item-title>


              <h6 class="py-2 primary--text">
                {{ 10 | currency }}
              </h6>
            </v-list-item-content>

          </v-list-item>
          <!--***-->
        </v-card-text>

      </v-card>
    </v-col>
    <!--end: demo card-->

    <v-col class="text-right" cols="12">
      <v-btn :disabled="this.loading" :loading="this.loading" @click="save" block depressed color="primary"
             class="mx-auto">
        {{ this.$t('generic.lang_save') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "ProductCardComponent",
  data: () => ({
    borderColorMenu: false,
    loading: false,
    borderForm: {
      border_size: 1,
      is_border_disabled: false,
      border_color: "#000000",
      border_radius: 3
    },
    borderColorWatcher: ""
  }),
  methods: {
    applyColor() {
      //format color
      if (typeof this.borderColorWatcher === 'string') {
        this.borderForm.border_color = this.borderColorWatcher;
      } else
        this.borderForm.border_color = JSON.stringify(Object.keys(this.borderColorWatcher).reduce((color, key) => {
          color[key] = Number(this.borderColorWatcher[key].toFixed(2))
          return color
        }, {}), null, 2);

      this.borderColorMenu = false;
    },
    save() {
      this.loading = true;
      this.axios.post(ENDPOINTS.KIOSK.SETTINGS.EXTRA_CSS.PRODUCTCARD.UPDATE, this.borderForm).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_ActionSuccessful'),
            color: "success",
          });
        } else Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.KIOSK.SETTINGS.EXTRA_CSS.PRODUCTCARD.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS" && typeof res.data.data === 'object') {
          this.borderForm = {...res.data.data}
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>

</style>
