<template>
  <b-card no-body class="mb-1 transparent">
    <b-card-header header-tag="header" v-b-toggle.accordion3 :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'p-1']" role="tab">
      <v-row>
        <v-col>
          <div class="pa-5 d-block">
            {{ this.$t('generic.lang_kioskImages') }}
          </div>
        </v-col>
      </v-row>
    </b-card-header>

    <b-card-body>
      <v-row>
        <v-col cols="12" sm="6">
          <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ this.$t('tablebee.lang_welcome_page_logo') }}
              </div>
            </div>
            <div class="card-body">
              <image-cropper ref="welcomePageLogo"
                             v-model="image.welcomePageLogo"/>
            </div>
            <div class="d-block text-right card-footer pa-0">
              <v-btn :disabled="loaders.logo" :loading="loaders.welcomePageLogo"
                     @click="upload(1)" block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0"
                     large>
                <v-icon class="ma-1">cloud_upload</v-icon>
                {{ this.$t('generic.lang_hochladen') }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ this.$t('tablebee.lang_favicon') }}
              </div>
            </div>
            <div class="card-body">
              <image-cropper ref="fabIcon"
                             v-model="image.fabIcon"/>
            </div>
            <div class="d-block text-right card-footer pa-0">
              <v-btn :disabled="loaders.logo" :loading="loaders.fabIcon"
                     @click="upload(2)" block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0"
                     large>
                <v-icon class="ma-1">cloud_upload</v-icon>
                {{ this.$t('generic.lang_hochladen') }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="6" md="6">
          <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ 'Logo' }}
              </div>
            </div>
            <div class="card-body">
              <image-cropper ref="logo"
                             v-model="image.logo"/>
            </div>
            <div class="d-block text-right card-footer pa-0">
              <v-btn :disabled="loaders.logo" :loading="loaders.logo"
                     @click="upload(3)" block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0"
                     large>
                <v-icon class="ma-1">cloud_upload</v-icon>
                {{ this.$t('generic.lang_hochladen') }}
              </v-btn>
            </div>
          </div>
        </v-col>

        <v-col cols="6" md="6">
          <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ this.$t('tablebee.lang_default_product_image') }}
              </div>
            </div>
            <div class="card-body">
              <image-cropper ref="product_image"
                             v-model="image.product_image"/>
            </div>
            <div class="d-block text-right card-footer pa-0">
              <v-btn :disabled="loaders.product_image" :loading="loaders.product_image"
                     @click="upload(4)" block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0"
                     large>
                <v-icon class="ma-1">cloud_upload</v-icon>
                {{ this.$t('generic.lang_hochladen') }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </b-card-body>

  </b-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import ImageCropper from "../../common/imagecropper";

export default {
  name: "KioskDesignImagesComponent",
  components: {
    ImageCropper
  },
  data() {
    return {
      loaders: {
        welcomePageLogo: false,
        fabIcon: false,
        logo: false,
        product_image: false
      },
      image: {
        welcomePageLogo: '',
        fabIcon: '',
        logo: '',
        product_image: ''
      },
    }
  },
  methods: {
    getLogos() {
      this.loading = true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.LOGOS.GET).then((res) => {
        if (res.status === 200) {
          this.image.welcomePageLogo = res.data.image.welcomePageLogo === 'data:image/;base64,' ? null : res.data.image.welcomePageLogo;
          this.image.logo = res.data.image.logo === 'data:image/;base64,' ? null : res.data.image.logo;
          this.image.fabIcon = res.data.image.fabIcon === 'data:image/;base64,' ? null : res.data.image.fabIcon;
          this.image.product_image = res.data.image.default_product_image === 'data:image/;base64,' ? null : res.data.image.default_product_image;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    upload(type) {
      let logoType = '';
      switch (type) {
        case 1:
          logoType = 'welcomePageLogo';
          break;
        case 2:
          logoType = 'fabIcon';
          break;
        case 3:
          logoType = 'logo';
          break;
        case 4:
          logoType = 'product_image';
          break;
      }

      this.$refs[logoType].cropImage();
      this.loaders[logoType] = true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.LOGOS.UPDATE, {
        logoUpload: this.image[logoType],
        typeOfLogo: type,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || res.data.status,
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loaders[logoType] = false;
      })
    },
  },
  mounted() {
    this.getLogos();
  }
}
</script>

<style scoped>

</style>
