import { render, staticRenderFns } from "./KioskTextColorsComponent.vue?vue&type=template&id=b8bb0920&scoped=true"
import script from "./KioskTextColorsComponent.vue?vue&type=script&lang=js"
export * from "./KioskTextColorsComponent.vue?vue&type=script&lang=js"
import style0 from "./KioskTextColorsComponent.vue?vue&type=style&index=0&id=b8bb0920&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8bb0920",
  null
  
)

export default component.exports