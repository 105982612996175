<template>
  <b-card no-body class="mb-1 transparent">
    <b-card-header header-tag="header" v-b-toggle.accordion5 :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'p-1']" role="tab">
      <v-row>
        <v-col>
          <div class="pa-5 d-block">
            {{ $t('tablebee.lang_privacy_and_allergens') }}
          </div>
        </v-col>
        <v-col align="end" class="pa-5 ">
          <v-btn depressed color="success" @click="updatePrivacy" :loading="loadingLayout">
            {{ $t('generic.lang_save') }}
          </v-btn>
        </v-col>
      </v-row>
    </b-card-header>

    <b-card-body>
      <b-tabs v-if="this.isTranslationEnabled">
        <b-tab active>
          <template #title>
            <v-btn text class="ma-0">
              <country-flag country="DE"/>
            </v-btn>
          </template>
          <v-row>
            <v-col class="" cols="12">
              <v-subheader><strong>Kiosk-Datenschutz und -Bedingungen</strong></v-subheader>
              <quill-editor id="privacy_editor" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="conditions.privacy">

              </quill-editor>

            </v-col>
          </v-row>
        </b-tab>

        <b-tab>
          <template #title>
            <v-btn text class="ma-0">
              <country-flag country="GB"/>
            </v-btn>
          </template>
          <v-row>
            <v-col class="" cols="12">
              <v-subheader><strong>Kiosk privacy and conditions</strong></v-subheader>
              <quill-editor id="privacy_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="conditions.privacy_en">

              </quill-editor>

            </v-col>
          </v-row>
        </b-tab>

        <b-tab>
          <template #title>
            <v-btn text class="ma-0">
              <country-flag country="FR"/>
            </v-btn>
          </template>
          <v-row>
            <v-col class="" cols="12">
              <v-subheader><strong>Confidentialité et conditions de Kiosk</strong>
              </v-subheader>
              <quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="conditions.privacy_fr">

              </quill-editor>

            </v-col>
          </v-row>
        </b-tab>

        <b-tab>
          <template #title>
            <v-btn text class="ma-0">
              <country-flag country="SA"/>
            </v-btn>
          </template>
          <v-row>
            <v-col class="" cols="12">
              <v-subheader><strong>الشروط والخصوصية</strong></v-subheader>
              <quill-editor id="privacy_editor_4" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="conditions.privacy_ar">

              </quill-editor>

            </v-col>
          </v-row>
        </b-tab>
      </b-tabs>
      <v-row v-else>
        <v-col class="" cols="12">
          <v-subheader><strong>{{ $t('generic.lang_kioskPrivacyPolicyAndTerms') }}</strong></v-subheader>
          <quill-editor id="privacy_editor" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :options="quillOptions"
                        @focus="showTouchKeyboard"
                        output="html" class="mb-4" v-model="conditions.privacy">

          </quill-editor>

        </v-col>
      </v-row>

      <!-- allergens -->
      <br/><br/>
      <b-tabs class="border-top pt-3" v-if="this.isTranslationEnabled">
        <b-tab active>
          <template #title>
            <v-btn text class="ma-0">
              <country-flag country="DE"/>
            </v-btn>
          </template>
          <v-row>
            <v-col class="" cols="12">
              <v-subheader><strong>{{ this.$t('erp.lang_Allergene') }}</strong></v-subheader>
              <quill-editor id="allergens_editor" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="conditions.allergens">
              </quill-editor>
            </v-col>
          </v-row>
        </b-tab>

        <b-tab>
          <template #title>
            <v-btn text class="ma-0">
              <country-flag country="GB"/>
            </v-btn>
          </template>
          <v-row>
            <v-col class="" cols="12">
              <v-subheader><strong>{{ this.$t('erp.lang_Allergene') }}</strong></v-subheader>
              <quill-editor id="allergens_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="conditions.allergens_en">

              </quill-editor>

            </v-col>
          </v-row>
        </b-tab>

        <b-tab>
          <template #title>
            <v-btn text class="ma-0">
              <country-flag country="FR"/>
            </v-btn>
          </template>
          <v-row>
            <v-col class="" cols="12">
              <v-subheader><strong>{{ this.$t('erp.lang_Allergene') }}</strong></v-subheader>
              <quill-editor id="allergens_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="conditions.allergens_fr">
              </quill-editor>

            </v-col>
          </v-row>
        </b-tab>

        <b-tab>
          <template #title>
            <v-btn text class="ma-0">
              <country-flag country="SA"/>
            </v-btn>
          </template>
          <v-row>
            <v-col class="" cols="12">
              <v-subheader><strong>{{ this.$t('erp.lang_Allergene') }}</strong></v-subheader>
              <quill-editor id="privacy_editor_8" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="conditions.allergens_ar">

              </quill-editor>

            </v-col>
          </v-row>
        </b-tab>
      </b-tabs>

    </b-card-body>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </b-card>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import CountryFlag from "vue-country-flag";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {createNamespacedHelpers} from "vuex";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

export default {
  name: "KioskDesignPrivacyComponent",
  mixins: [mixin],
  components: {
    CountryFlag, quillEditor
  },
  data() {
    return {
      loadingLayout: false,
      conditions: {
        privacy: "",
        privacy_en: "",
        privacy_fr: "",
        privacy_ar: "",
        allergens: "",
        allergens_en: "",
        allergens_fr: "",
        allergens_ar: ""
      },


      quillOptions: {

        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ],
        },
      },
    }
  },
  computed: {
    ...createNamespacedHelpers("settings").mapGetters([
      "getSettingValue"
    ]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    }
  },
  methods: {
    updatePrivacy() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.CONDITIONS.UPDATE, {
        conditions: this.conditions
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    },

    getConditions() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.DESIGN.CONDITIONS.GET)
          .then(res => {
            if (res.status === 200) {
              this.conditions = res.data.conditions
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      })
    }
  },
  mounted() {
    this.getConditions();
  }
}
</script>

<style scoped>

</style>
